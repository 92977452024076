export default [
  {
    header: 'Items',
    icon: 'ClipboardIcon',
    children: [
      {
        title: 'Items',
        route: 'items.list',
        icon: 'SettingsIcon',
        resource: 'Product',
        action: 'index',
      },
      {
        route: 'items.store',
      },
      {
        route: 'items.edit',
      },
      {
        title: 'Categories',
        route: 'categories.list',
        icon: 'LayersIcon',
        resource: 'Category',
        action: 'index',
      },
    ],
  },
]
